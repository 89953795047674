
.home {
  text-align: left;
  
}

.hero {
    position: relative;
    background-color: #001f3f;
    background-image: url("../images/home2.png"); /* Added semicolon here */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 90vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: left;
}



.homepage-hero {
  margin-left: 10rem !important;
}

.hero-content-home {
  text-align: left; /* Set to left align */
  padding: 20px;
  z-index: 2;
  margin-left: 5rem !important ; /* Ensure content is above the overlay */
}

.hero h1 {
  color: white;
  font-size: 4rem;
  margin: 0;
  font-weight: 800;
}

.hero p {
  font-size: x-large;
  margin: 10px 0;
  color: #ffffff;
}

.btn-1 {
  color: #001f3f !important;
  background-color: white;
}

.hero-button1 {
    color: white ;
    border: solid 1px white ;
}

.hero-button2:hover {
  color: white !important;
  border: solid 1px white ;
;
}

.hero-button1:hover {
  color: #001f3f !important;
background-color: white;
}

.hero-buttons {
  margin-top: 20px;
}

/* Override Bootstrap button styles */
.btn.hero-button {
  background-color: #ffffff !important; /* Ensure the color is applied */
  border: solid 1px rgb(255, 255, 255) !important;
  
  color: #001f3f;
}

.btn.hero-button:hover {
  background-color: #000000 !important; /* Adjust hover color if desired */
}

.hero-button {
  font-size: large;
  border: 1px solid white !important;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


@media (max-width: 1400px) {
  .hero-content-home {
margin-left: 2rem !important;
 
  }

  .hero {
    background-image: none !important;
  }

  
}

@media (max-width: 1200px) {


  .hero {
    background-image: none !important;
  }

  
}

@media (max-width: 768px) {
  .hero-content-home {
margin-left: 0rem !important;
  }

  .hero h1 {
    font-size: 3rem !important;
  }

}

@media (max-width: 480px) {

  .hero h1 {
    font-size: 2.5rem !important;
  }

  .hero p {
    font-size: larger !important; 
  }

}



.custom-select-dropdown {
  width: auto;
  display: flex;
  align-items: center;
}

.dropdown-toggle-custom {
  max-width: 300px; /* Slightly increase the width of the dropdown button */
  font-weight: bold; /* Make text bold */
  color:#001f3f;
  border-color: whitesmoke;
  background-color: whitesmoke;
  overflow: hidden;
  text-align: left;

}

.dropdown-toggle-custom:hover {
  background-color: white;
  color: #001f3f;
}

.dropdown-menu-custom {
  max-height: 200px; /* Set maximum height for dropdown */
  overflow-y: auto;  /* Enable vertical scrolling */
}

.button-bold {
  font-weight: bold; /* Make button text bold */
  border-color: whitesmoke;
background-color: #001f3f;
}

.button-bold:hover {
  background-color: white;
  color: #001f3f;
}


.ml-2 {
  margin-left: 8px; /* Margin between dropdown and button */
}

.text-danger {
  margin-top: 5px;
  margin-left: 1rem;
}

.quoteTypeSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.headerContainer {
  margin-bottom: 3rem; /* Spacing between the header and the cards */
  text-align: center;
}

.large-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 2rem;
}

.large-card {
  width: 400px;
  height: 200px;
  cursor: pointer;
}

.large-card:hover {
  transform: scale(1.02);
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit:fill;
  border-radius: 8px !important;
}

.quote-header {
  font-weight: bold !important;  
  color: #001f3f;
}

.card-biz {
  margin-left: 1rem;
  margin-right: 1rem;

}

.card-pers {
  margin-right: 1rem;
  margin-left: 1rem;
}

.quote-text {
  margin-bottom: -2rem;
  margin-top: .5rem !important;
}

.quoteTypeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}



.backButton1 {
  cursor: pointer;
  font-size: 16px;
  background-color: #ccc;
  color: black;  
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2rem;
}

@media (max-width: 1800px) {
  .quoteTypeCard {
    width: 600px !important;
    height: 400px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}


@media (max-width: 1500px) {
  .quoteTypeCard {
    width: 500px !important;
    height: 300px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .quoteTypeCard {
    width: 600px !important;
    height: 350px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 630px) {
  .quoteTypeCard {
    width: 550px !important;
    height: 300px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 570px) {
  .quoteTypeCard {
    width: 450px !important;
    height: 300px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .quoteTypeCard {
    width: 400px !important;
    height: 250px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 420px) {
  .quoteTypeCard {
    width: 350px !important;
    height: 250px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}

@media (max-width: 380px) {
  .quoteTypeCard {
    width: 300px !important;
    height: 200px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
}



/* Margin for all cards */
.quoteTypeCard {
  margin: 20px; /* Adjust margin as needed */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effects for cards */
.quoteTypeCard:hover {
  
  transform: scale(1.02); /* Slightly scale up the card */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Add shadow for better visibility */
}

.backButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 2rem; /* Adjust padding as needed */
}

.backButton1 {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background-color: #ccc;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

.backButton1:hover {
  background-color: #bbb; /* Darker shade on hover */
}
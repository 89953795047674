
.serv-marg {
    margin-top: 3rem;
  }
  
  
  .about-meet-title {
    text-align: center;
    font-size: 3rem !important;
    font-weight: bold !important;
    margin-top: 1rem;
    color: #001f3f;
    font-weight: 550;
    line-height: 60px;
  }

  .about-meet-body {
  text-align: center;
  font-size: 1.4rem !important;
  margin-bottom: 2rem;
  font-weight: 500 !important;
  color: rgb(51, 51, 51) !important;
  }

  
  .serv-img {
    border-radius: 10px;
    margin-left: 4rem;
    width: 80%;
  }
  
  
  
  .serv-page {
    font-weight: 550;
    margin-top: -1rem !important;
  }
  
  .service-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-left: 2rem;
    text-align: left;
    border: none;
    box-shadow: none;
  }
  
  .service-icon {
    font-size: 3em;
    color: #001f3f;
  }
  
  .serv-head {
    text-align: left;
  font-size: 3rem !important;
  font-weight: bold !important;
  margin-top: 10px;
  color: #001f3f;
  font-weight: 550;
  line-height: 60px;
  }
  
  .serv-body {
        font-weight: 550;
        text-align: left;
        line-height: 32px;
        color: hsla(0, 0%, 20%, 0.7);
        font-size: 18px;
        max-width: 600px;
        transition: color 0.3s ease-in-out;
      }
  
  
  
  .serv-head {
    text-align: left !important;
  }
  
  .serv-body {
    text-align: left;
  }
 


  
  
  @media (max-width: 550px) {
  
    .about-serv-head {
      font-size: 2.3rem !important;
    }

      
  .service-item {
    
    margin-left: 0rem !important;
    text-align: left;
    
  }

  .serv-img {
    margin-left: 0rem !important;
    width: 100%;
  }
  
  }
  
  /* Services.css */
  @media (max-width: 992px) {
    .services-content .row .col-md-12:first-child {
      order: 2;
    }
    .services-content .row .col-md-12:last-child {
      order: 1;
    }
  }
  
  @media (max-width: 992px) {
    .img-card {
      margin-top: 2rem; /* Add margin above each image card on screens smaller than 992px */
    }
  }
  


.single_advisor_profile {
    position: relative;
    margin-bottom: 80px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    z-index: 1;
    border-radius: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
}

.single_advisor_profile .advisor_thumb {
    position: relative;
    z-index: 1;
    border-radius: 15px 15px 0 0;
    margin: 0 auto;
    padding: 30px 30px 0 30px;
    background-color: #001f3f;
    overflow: hidden;
}

.single_advisor_profile .advisor_thumb::after {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 150%;
    height: 80px;
    bottom: -45px;
    left: -25%;
    content: "";
    background-color: #ffffff;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

@media only screen and (max-width: 575px) {
    .single_advisor_profile .advisor_thumb::after {
        height: 160px;
        bottom: -90px;
    }
}

.single_advisor_profile .advisor_thumb .social-info {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    right: 30px;
    text-align: right;
}

.single_advisor_profile .advisor_thumb .social-info a {
    font-size: 14px;
    color: #001f3f;
    padding: 0 5px;
}

.single_advisor_profile .advisor_thumb .social-info a:hover,
.single_advisor_profile .advisor_thumb .social-info a:focus {
    color: #001f3f;
}

.single_advisor_profile .advisor_thumb .social-info a:last-child {
    padding-right: 0;
}

.single_advisor_profile .single_advisor_details_info {
    position: relative;
    z-index: 1;
    padding: 30px;
    text-align: right;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 0 0 15px 15px;
    background-color: #ffffff;
}

.single_advisor_profile .single_advisor_details_info::after {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 3px;
    background-color: #001f3f;
    content: "";
    top: 12px;
    right: 30px;
}

.single_advisor_profile .single_advisor_details_info h6 {
    margin-bottom: 0.25rem;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_advisor_profile .single_advisor_details_info h6 {
        font-size: 14px;
    }
}

.single_advisor_profile .single_advisor_details_info p {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 0;
    font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_advisor_profile .single_advisor_details_info p {
        font-size: 12px;
    }
}

.single_advisor_profile:hover .advisor_thumb::after,
.single_advisor_profile:focus .advisor_thumb::after {
    background-color: #001f3f;
}

.single_advisor_profile:hover .advisor_thumb .social-info a,
.single_advisor_profile:focus .advisor_thumb .social-info a {
    color: #ffffff;
}

.single_advisor_profile:hover .advisor_thumb .social-info a:hover,
.single_advisor_profile:hover .advisor_thumb .social-info a:focus,
.single_advisor_profile:focus .advisor_thumb .social-info a:hover,
.single_advisor_profile:focus .advisor_thumb .social-info a:focus {
    color: #ffffff;
}

.single_advisor_profile:hover .single_advisor_details_info,
.single_advisor_profile:focus .single_advisor_details_info {
    background-color: #001f3f;
}

.single_advisor_profile:hover .single_advisor_details_info::after,
.single_advisor_profile:focus .single_advisor_details_info::after {
    background-color: #ffffff;
}

.single_advisor_profile:hover .single_advisor_details_info h6,
.single_advisor_profile:focus .single_advisor_details_info h6 {
    color: #ffffff;
}

.single_advisor_profile:hover .single_advisor_details_info p,
.single_advisor_profile:focus .single_advisor_details_info p {
    color: #ffffff;
}

@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
.serv-card-title {
  color: #001f3f; /* Dark blue color */
}

/* Apply styles for all tabs */
.nav-tabs .nav-link {
  color: #001f3f; /* Text color inside the tab */
  font-weight: bold; /* Make the font bold */
  border: 1px solid transparent; /* Ensure there's no default border */
}

.nav-tabs .nav-link.active {
  background-color: #001f3f; /* Keep the active tab's background the same */
  color: #ffffff; /* Text color for active tab */
  font-weight: bold; /* Bold text for active tab */
  border-color: #001f3f; /* Set border color to match the tab */
}


.custom-card {
  border-radius: 15px; /* Adds rounded borders */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  transition: transform 0.3s ease; /* Smooth transformation on hover */
}

.serv-card-title {
  font-weight: bold;
  color: #001f3f !important;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 2rem;
  padding-bottom: 8px;
  margin-bottom: 0rem !important;
  transition: color 0.3s ease-in-out;
}

.btn-responsive {
  width: 100%; /* Adjust width for responsiveness */
  max-width: 150px; /* Set a maximum width to prevent it from growing too large */
  font-size: 1rem;
  border: none;
  padding: .5rem; /* Adjust font size for consistent look */
}


.serv-card-title1 {
  font-weight: bold;
  color: #001f3f !important;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 2.2rem;
  padding-bottom: 8px;
  margin-bottom: 0rem !important;
  transition: color 0.3s ease-in-out;
}

.service-descriptions {
  font-weight: 550;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.7);
  font-size: 18px;
  max-width: 470px;
  transition: color 0.3s ease-in-out;
}

.service-descriptions-tab {
  font-weight: 500;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.8);
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.btn-service {
  background-color: #001f3f;
  color: white;
  font-weight: 550;
  font-size: large;
  border-radius: 5px;
}

.btn-service:hover {
  background-color: #2e2e2e;
  color: white;
  font-weight: 550;
  font-size: large;
}

.card-text-services {
  font-weight: 550;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.7);
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.card-text-services1 {
  font-weight: 550;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.7);
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.service-descriptions2 {
  font-weight: 550;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.7);
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

.custom-card:hover {
  transform: scale(1); /* Slightly enlarges the card on hover */
}

.fa-icon {
  color: var(--primary-color); /* Adjust color to match your theme */
}


/* Flip every second card */
.postcard:nth-child(2n) {
  flex-direction: row-reverse; /* Flip the card */
}


/* Make the images smaller */
.small-img {
  max-width: 100%;
  height: 350px; /* Adjust this height as needed */
  object-fit: cover;
}

/* Align content to the left */
.text-left {
  text-align: left;
}






a, a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
}

/* Cards */
.postcard {
  margin-top: 3rem !important;
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.postcard.dark {
  background-color: whitesmoke;
}

.postcard.light {
  background-color: whitesmoke;
}

.t-dark {
  color: #001f3f;
}

.postcard__title {
  font-size: 1.75rem;
}

.postcard__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.postcard__img_link {
  display: contents;
}

.postcard__bar {
  width: 350px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #001f3f;
  transition: width 0.2s ease;
}

.postcard__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.postcard__preview-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  height: 100%;
}

.postcard__tagbox {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0;
  justify-content: center;
}

.tag__item {
  display: inline-block;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 3px;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}

.tag__item:hover {
  background: rgba(83, 83, 83, 0.8);
}

.postcard:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 1;
  border-radius: 10px;
}

.postcard:hover .postcard__bar {
  width: 400px;
}

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }

  .postcard__title {
    font-size: 2rem;
  }

  .postcard__tagbox {
    justify-content: start;
  }

  .postcard__img {
    max-width: 300px;
    max-height: 100%;
    transition: transform 0.3s ease;
  }

  .postcard__text {
    padding: 3rem;
    width: 100%;
  }



  .postcard:nth-child(2n+1) {
    flex-direction: row;
  }

  .postcard:nth-child(2n) {
    flex-direction: row-reverse;
  }

  .postcard:nth-child(2n+1) .postcard__text::before {
    left: -12px !important;
    transform: rotate(4deg);
  }

  .postcard:nth-child(2n) .postcard__text::before {
    right: -12px !important;
    transform: rotate(-4deg);
  }
}

@media screen and (min-width: 1024px) {
  .postcard__text {
    padding: 2rem 3.5rem;
  }

  .postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .postcard.dark .postcard__text:before {
    background: whitesmoke;
  }

  .postcard.light .postcard__text:before {
    background: whitesmoke;
  }
}

/* COLORS */
.green .postcard__title:hover {
  color: #001f3f;
}

.green .postcard__bar {
  background-color: #001f3f;
}

.green:before {
  background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}

.green:nth-child(2n):before {
  background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}

.blue .postcard__title:hover {
  color: #001f3f;
}

.blue .postcard__bar {
  background-color: #001f3f;
}

.blue:before {
  background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}

.blue:nth-child(2n):before {
  background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}

.red .postcard__title:hover {
  color: #001f3f;
}

.red .postcard__bar {
  background-color: #001f3f;
}

.red:before {
  background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}

.red:nth-child(2n):before {
  background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}

.yellow .postcard__title:hover {
  color: #001f3f;
}

.yellow .postcard__bar {
  background-color: #001f3f;
}

.yellow:before {
  background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}

.yellow:nth-child(2n):before {
  background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}

@media screen and (min-width: 769px) {
  .green:before {
    background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
  }

  .green:nth-child(2n):before {
    background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
  }

  .blue:before {
    background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
  }

  .blue:nth-child(2n):before {
    background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
  }

  .red:before {
    background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
  }

  .red:nth-child(2n):before {
    background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
  }

  .yellow:before {
    background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
  }

  .yellow:nth-child(2n):before {
    background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
  }
}


.faq-services {
  font-size: 2.3rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  text-align: center;
  color: #001f3f;
  font-weight: 900;


}

.faq-section {
  margin-bottom: 5rem;
}

.accordion-button:not(.collapsed) {
  background-color: #f8f9fa; /* Light grey background when active */
  color: #001f3f; /* Orange text color when active */
  border-color: #001f3f; /* Orange border when active */
}

/* Remove the blue border on focus */
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

/* Change text and background to orange on hover */
.accordion-button:hover {
  color: #001f3f !important; /* Orange text color on hover */
  background-color: #f8f9fa !important; /* Light grey background on hover */
  border-color: #001f3f !important; /* Orange border on hover */
}

.accordion-item .accordion-header .faq-head {
  font-weight: bold !important;
  font-size: large;
  color: #000; /* Default color when collapsed */
}

/* When the accordion is expanded */
.accordion-item .accordion-header:not(.collapsed) .faq-head {
  color: #001f3f !important; /* Orange text color when expanded */
}

/* Ensure the accordion button has the correct font family and weight when active or collapsed */
.accordion-button {
  font-weight: bold !important;
  font-size: large;
}



/* When the accordion is expanded */
.accordion-button:not(.collapsed) {
  color: #001f3f !important; /* Orange text color when expanded */
  border-color: #001f3f !important; /* Orange border when expanded */
}

/* Apply the K2D font to the Accordion Body */
.accordion-body {
  font-size: medium; /* You can adjust the font size as needed */
  color: #1d1d1d; /* Default text color for the accordion body */
}

/* Remove faint orange background on hover, just change the text color */
.accordion-button:hover {
  color: #001f3f; /* Orange text color on hover */
  border-color: #000000; /* Keep border black on hover */
}

@media (max-width: 992px) {
  .biz-marg {
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
.postcard__bar {
  width: 200px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #001f3f;
  transition: width 0.2s ease;
}

.postcard:hover .postcard__bar {
  width: 250px;
}

}
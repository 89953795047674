.networks-container {
    padding: 20px;
  }
  
  .logo-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    justify-items: center;
  }
  
  /* Adjust logo size for different screens */
  .logo-item img {
    width: 200px; /* Default size for larger screens */
    height: auto;
    margin-bottom: -1rem;
    max-width: 100%; /* Ensures the logo scales well */
  }
  
  .divider-networks {
    color: rgb(139, 139, 139);
  }
  
  .unique-services-section__container-networks {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    margin-bottom: 0rem;
    color: rgb(51, 51, 51) !important;
    line-height: 60px;
    letter-spacing: 1px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .logo-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .logo-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .logo-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablet screens */
    }
  
    .logo-item img {
      width: 150px; /* Smaller logo size for tablet */
    }
  }
  
  @media (max-width: 576px) {
    .logo-grid {
      grid-template-columns: repeat(2, 1fr); /* 1 column for mobile screens */
    }
  
    .logo-item img {
      width: 120px; /* Smaller logo size for mobile */
    }
  }

  @media (max-width: 390px) {
  .unique-services-section__container-networks {
    font-size: 1.15rem !important;
    
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

html, body {
  width: 100%;
}

* {
  box-sizing: border-box; /* Ensures padding and borders are included in the element's width and height */
}

.container {
  max-width: 100%; /* Ensure containers fit within the viewport */
}

.footer-section {
  background-color: #001f3f; /* Navy blue background */
  color: #ffffff;
  padding: 20px 0;
}

.footer-links a {
  color: inherit; /* Ensures links inherit color from parent */
  text-decoration: none; /* Removes default underline */
  transition: all 0.3s ease; /* Adds smooth transition */
}

.footer-links a:hover {
  text-decoration: underline; /* Adds underline on hover */
}


.footer-info {
  font-weight: bold;
}

.company-name {
  font-weight: bold;
}

.footer-section .row {
  margin: 0;
}

.footer-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-item p {
  margin-left: 10px;
  margin-bottom: 0; /* Remove bottom margin for better alignment */
  font-size: 14px;
}

.footer-item i,
.footer-item p,
.footer-item h4,
.footer-item a {
  color: #ffffff;
}

.footer-section a {
  text-decoration: none;
}

.footer-section a:hover {
  color: #ff5e14;
}

.footer-links {
  padding: 0;
}

.footer-links .list-inline-item {
  margin: 0 10px;
}

.footer-links .list-inline-item a {
  color: #ffffff;
  font-weight: 500;
}

.copyright-text {
  font-weight: 500;
}

.footer-links .list-inline-item a:hover {
  color: #c7c7c7;
}

.footer-divider {
  border-color: rgba(255, 255, 255, 0.2); /* Light gray with low opacity */
  margin: 0;
}


@media (max-width: 576px) {
  .foot-item1 {
    margin-top: 1rem;
  }
}
.testimonials-section {
    background-color: #001f3f; /* Navy blue background */
    padding-left: 0;
    padding-right: 0;
}

.head-test {
    font-size: 3rem;
    font-weight: 550 !important;
}

.stars {
    color: gold;
    font-size: 18px;
}

.testimonial-card {
    background-color: #001f3f;
    border: none;
    border-radius: 10px;
    text-align: center;
}


.text-test {
    color: whitesmoke !important;
    font-size: 1.2rem !important;
    font-weight: 550 !important;
}

.testimonial-text {
    font-size: 1.2rem;
    color: whitesmoke !important;
    font-style: italic;
    text-align: center;
    font-weight: 500;
}

.testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff !important;
    text-align: center;
}

.testimonial-title {
    font-size: 0.9rem;
    color: #ffffff !important;
    text-align: center;
}

.subheader {
    font-size: 1.1rem;
    color: #dddddd;
}

@media (max-width: 768px) {
    .testimonial-card {
        padding: 15px;
        min-width: 100%; /* Take full width on smaller screens */
    }

    .testimonial-text {
        font-size: 0.9rem;
    }

    .testimonial-name {
        font-size: 1.1rem;
    }

    .testimonial-title {
        font-size: 0.8rem;
    }
}
.contact-container {
  padding: 0;
  position: relative;
}

.map-container iframe {
  transition: opacity 0.3s ease-in-out; /* Adjust the lead-in speed */
  opacity: 0; /* Initial state */
}

.map-container iframe[data-loaded="true"] {
  opacity: 1; /* Final state after loading */
}

.contact-page1 {
  margin-top: 3rem !important;
  margin-bottom: 5rem !important;
}

.contact-header {
  background-color: #001f3f;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-bottom: 5% !important; /* Push the content 10% above the bottom */
}

.contact-header h1, 
.contact-header p {
  margin: 0;
}

.contact-jumbo {
  font-size: x-large;
  margin: 10px 0;
  color: #ffffff;
}

.form-title {
  font-size: 2.5rem !important;
  color: rgb(0, 0, 0);
  font-weight: bold !important;
}

.contact-header .display-4 {
  font-size: 3.5rem;
  color: white;
  font-weight: 800;
}

.contact-header .lead {
  font-size: 1.25rem;
  color: whitesmoke;
}

.contact-form-wrapper {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: bold !important;
  color: #001f3f;
}

.contact-map {
  position: relative;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: none;
  position: relative;
  z-index: 1;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}





@media  (max-width: 992px) {
  .contact-header {
    height: 25vh;

  }
  .contact-jumbo {
    font-size: large;
    margin-bottom: 2rem !important;
    margin-top: .5rem !important;
    margin: 10px 0;
    color: #ffffff;
  }
  
}

@media  (max-width: 768px) {


  .contact-header .display-4 {
    font-size: 3rem;
    
  }
}

@media  (max-width: 550px) {


  .contact-header .display-4 {
    font-size: 2.2rem;
    
  }
  .contact-jumbo {
    font-size: large;
    margin-bottom: 1rem !important;
    margin-top: .5rem !important;
    margin: 10px 0;
    color: #ffffff;
  }
  .contact-header {
    padding-bottom: 8% !important; /* Push the content 10% above the bottom */

  }
}
@media  (max-width: 400px) {


  .contact-header .display-4 {
    font-size: 2.2rem;
    
  }
  .contact-jumbo {
    font-size: medium;
    color: #ffffff;
    margin-bottom: 0rem !important;
  }
  .contact-header {
    height: 30vh;
   
  }

  .contact-header {
    padding-bottom: 10% !important; /* Push the content 10% above the bottom */

  }
}
@media (max-width: 992px) {
  .contact-map {
    margin-top: 20px; /* Adds margin above the map */
  }

  /* Additional adjustments if needed */
}
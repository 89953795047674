.hs-section {
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 5rem;
  background-color: #001f3f; /* Navy blue background */
padding-bottom: 5rem;
}

.social-titles {
  text-align: left;
  margin-bottom: -1rem !important;
}

.socials-head {
  margin-top: 1rem;
}

.socials-marg {
  margin-bottom: 2.5rem;
}

.hs-header {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hs-subheader {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #666;
}

.hs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  gap: 2rem;
  margin: 0 auto; /* Centers the entire container */
}

.hs-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2), 0 6px 20px rgba(255, 255, 255, 0.19); /* Enhanced box shadow */
  background: #ECE9E6;
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  margin: 0 auto; /* Centers individual cards */
}


.hs-card__header {
  width: 100%;
}

.hs-card__image {
  max-width: 100%;
  border-radius: 5px;

  display: block;
  object-fit: cover;
  border: 1px solid white; /* Add a white border around the image */

}

.hs-card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.hs-tag {
  align-self: flex-start;
  padding: .25em .75em;
  border-radius: 1em;
  font-size: .75rem;
}

.hs-tag-pink {
  background: #3b5998;
  color: #fafafa;
}

.hs-tag-blue {
  background: #3b5998;
  color: #fafafa;
}

.hs-tag-black {
  background: #3b5998;
  color: #fafafa;
}

.hs-card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.hs-card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.hs-user {
  display: flex;
  gap: .5rem;
}

.hs-user__info > small {
  color: #666;
}

.contact-page-container {
  width: 100%;
  padding: 20px;
  background: none; /* Remove background */
  color: black;
}

.contact-formspree {
  color: black !important;
}

.contact-page .form-group {
  margin-bottom: 15px;
}

.contact-page label {
  margin-bottom: 5px;
  color: black;
}

.contact-page input,
.contact-page textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black; /* Add black outline */
  font-size: 1em;
  color: #333;
}

.contact-page input::placeholder,
.contact-page textarea::placeholder {
  color: #999;
}

.contact-page .btn-submit {
  padding: 10px 25px;
  background-color: #001f3f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contact-page .btn-submit:hover {
  background-color: #000000;
}

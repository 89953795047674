.contactForm {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  
  .confirmation {
    text-align: left;
    margin-top: 2rem;
  }
  
  .formSummary {
    margin-top: 1rem;
    text-align: left;
    color: #555;
    margin-bottom: 3rem !important;
  }
  
  .formSummary p {
    margin-bottom: 0.5rem;
  }
  
  .contactForm button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .backButton {
    background-color: #ccc;
    color: black;
  }
  
  .continueButton {
    background-color: #001f3f;
    color: white;
  }
  
  .backButton:hover, .continueButton:hover {
    opacity: 0.9;
  }
  
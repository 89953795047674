header.navbar {
  background-color: #001f3f;
  padding: 10px 20px;
}

.navbar-brand img {
  height: 50px;
}

.navbar-nav {
  margin-left: auto;
}

.navbar-nav .nav-link {
  color: white;
  margin: 10px 0; /* Adds margin above and below each nav link */
  font-size: large;
  text-decoration: none;
  font-weight: bold;
  margin-right: 1rem;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link:not(.btn-login)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover::before,
.navbar-nav .nav-link.active::before {
  visibility: visible;
  width: 100%;
  background-color: white;
}

.navbar-nav .nav-link.active {
  color: white;
}

.navbar-nav .nav-link.btn-login {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
}

.navbar-brand {
  font-weight: bold !important;
  margin: 0;
  color: #ffffff !important; /* Adjust color to your preference */
}

.navbar-nav .nav-link.btn-login:hover {
  background-color: #000000;
}

/* Hide the default navbar-toggler-icon */
.navbar-toggler-icon {
  display: none;
}

/* Remove background, border, and outline from the burger menu */
.navbar-toggler {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  position: relative;
  width: 30px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height to match the total height of the three lines */
  cursor: pointer;
}

/* Ensure no background or outline appears when focused or active */
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
  background: none;
}

/* Custom burger menu icon */
.navbar-toggler .line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

/* Position the lines */
.navbar-toggler .line:nth-child(1) {
  top: 0;
}

.navbar-toggler .line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.navbar-toggler .line:nth-child(3) {
  bottom: 0;
}

/* Transform to X icon */
.navbar-toggler.open .line:nth-child(1) {
  transform: rotate(45deg);
  top: 50%;
}

.navbar-toggler.open .line:nth-child(2) {
  opacity: 0; /* Hide the middle line */
}

.navbar-toggler.open .line:nth-child(3) {
  transform: rotate(-45deg);
  top: 50%;
}


@media (max-width: 500px) {
  .navbar-brand {
    font-size: medium;
  }
}
.contactForm {
    display: flex;
    flex-direction: column;
  }
  
  .formRow {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
  }
  
  .formMessage {
    margin-top: 1rem;
  }
  
  .formMessage1 {
    margin-bottom: 2rem;
  }
  
  .contactForm label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #001f3f;
  }
  
  .contactForm input,
  .contactForm select,
  .contactForm textarea {
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    color: #333;
    font-size: 1rem;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .contactForm button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .backButton {
    background-color: #ccc;
    color: black;
  }
  
  .continueButton {
    background-color: #001f3f;
    color: white;
  }
  
  .backButton:hover, .continueButton:hover {
    opacity: 0.9;
  }
  
  .errorText {
    color: red;
    font-size: 0.875rem;
  }
  
  .back-modal {
    padding: 1rem !important;
    font-weight: bold;
  }

  .doublecheck-head {
    margin-top: 1.5rem;
    font-weight: bold;
  }

  .formSummary {
    color: #001f3f !important;
    font-size: large;
  }

  .thank-you-text {
    color: #001f3f;
    font-size: large;
    margin-bottom: 10px;
  }
  .thankyou-head {
    color: #001f3f;
    font-weight: 550;
  }

  .continue-button {
    padding: 1rem !important;
    font-weight: bold;
  }

  .progressContainer {
    width: 100%;
    height: 10px;
    background-color: #dfe6e9;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
  }
  
  .progressBar {
    height: 100%;
    background-color: #001f3f;
    transition: width 0.3s ease;
  }
  
  .modal-head {
    font-weight: 600;
    color: #001f3f;
    font-size: 2rem;
  }

  .custom-modal-close {
    font-size: 20px; /* Make the close button larger */
    margin-right: 1rem; /* Add margin to the right */
  }
  
  @media (max-width: 500px) {
    .modal-head {
      
      font-size: x-large;
    }
  }

  .email-text {
    margin-top: 1rem;
  }
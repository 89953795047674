.blog-section-container {
    padding: 2rem 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .blog-header {
    margin-bottom: 2rem;
  }
  
  .blog-subtitle {
    font-size: 1.25rem;
    color: #555;
  }
  
  .blog-card {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 1.5rem;
    padding: 1.5rem;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-10px);
  }
  
  .blog-card-img-holder {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .blog-card-img-holder img {
    width: 100%;
    height: auto;
    max-height: 15rem;
    object-fit: cover;
    border-radius: 1.5rem;
  }
  
  .blog-card-title {
    color: #22215B;
    padding: 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .blog-card-description {
    padding: 0.5rem 0;
    color: #22215B80;
    font-size: 1rem;
  }
  
  .blog-card-time {
    font-size: 0.9rem;
    color: #22215B;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .blog-card-options span {
    font-weight: 600;
    color: #22215B;
    cursor: pointer;
  }
  
  .blog-card-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 400;
    background: #EEF7FE;
    color: #22215B;
    cursor: pointer;
    border: none;
  }
  
  .blog-card-btn:hover {
    background: #d9eaff;
  }
  
:root {
  --coverage-surface-color: #001f3f;
  --coverage-primary-color: #001f3f;
}

.coverage-container * {
  box-sizing: border-box;
}



.coverage-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed three columns */
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.coverage-text-test1 {
  font-size: 1.2rem;
  font-weight: 550;
}



.coverage-card {
  position: relative;
  display: block;
  width: 100%;
  height: 300px; /* Set a specific height */
  overflow: hidden !important;
  text-decoration: none;
  background-color: var(--coverage-primary-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.coverage-card__icon {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire card area */
  display: block;
  transition: 0.2s ease-in-out;
}

.coverage-card__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.coverage-card:hover .coverage-card__image-overlay {
  opacity: 1;
}

.coverage-card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--coverage-surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.coverage-card:hover .coverage-card__overlay {
  transform: translateY(0);
}

.coverage-card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--coverage-surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.coverage-card:hover .coverage-card__header {
  transform: translateY(0);
}

.coverage-card__title {
  font-size: 1.5em;
  text-align: left;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: -0.3rem;
  letter-spacing: 1px;
}

.coverage-card__description {
  color: #ffffff;
  margin-left: 1rem;
  font-size: medium;
  text-align: left;
}
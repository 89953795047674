/* Updated styles with unique class names */
.unique-services-section {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.unique-services-section__svg-background {
  position: absolute;
  top: 0;
  left: -500px;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  animation: moveSvgUpAndDown 5s ease-in-out infinite;
}

.unique-services-section__container-title {
  text-align: center;
  font-size: 3rem !important;
  font-weight: bold !important;
  margin-top: 4rem;
  color: #001f3f;
  font-weight: 550;
  line-height: 60px;
}

.unique-text-test1 {
  text-align: center;
  margin-top: -10px !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  color: rgb(51, 51, 51) !important;
}

.unique-services-section__gradient-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding: 30px;
  margin-bottom: 4rem;
}

.unique-services-section__card {
  position: relative;
  background-color: whitesmoke;
  border-radius: 10px;
  margin-left: .3rem;
  margin-right: .3rem;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add this line */
}


.unique-services-section__card:hover {
  background-color: #001f3f;
}

.unique-services-section__card:hover .unique-services-section__card-title,
.unique-services-section__card:hover .unique-services-section__card-description,
.unique-services-section__card:hover .unique-services-section__animated-icon {
  color: white;
}

.unique-services-section__container-card {
  padding: 40px;
  border-radius: 45px;
  width: 100% !important;
  z-index: 1;
}

.unique-services-section__card-title {
  font-weight: 600;
  color: #001f3f;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 28px;
  padding-bottom: 8px;
  margin-bottom: 0rem !important;
  margin-top: 1rem;
  transition: color 0.3s ease-in-out;
}

.unique-services-section__card-description {
  font-weight: 550;
  line-height: 32px;
  color: hsla(0, 0%, 20%, 0.7);
  font-size: 18px;
  max-width: 470px;
  transition: color 0.3s ease-in-out;
}

.unique-services-section__animated-icon {
  transition: color 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .unique-services-section__gradient-cards {
    grid-template-columns: 1fr;
  }

  .unique-services-section__svg-background {
    width: 100%;
    height: auto;
  }
}
